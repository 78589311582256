import * as React from 'react';
import { clsx } from 'clsx';
import { ProductBadge } from '../product-badge';
import { Divider } from '../divider';
import { TextWithHighlights } from './text-with-highlights';
import styles from './price-plan.strict-module.css';
import { BenefitsGroup } from './benefits-group';
import { AddonsGroup, type AddonsGroupProps } from './addons-group';

export type TraitName = 'free_trial' | 'popular' | 'active' | 'recommended';

export interface Trait {
  name: TraitName;
  title: string;
}

interface Benefits {
  title: string;
  benefits: Benefit[];
}

interface Benefit {
  description: string;
  isIncluded: boolean;
}

export interface PricePlanBaseProps {
  title: string;
  traits: Trait[];
  price: string;
  pricePeriod: string;
  description: string;
  ctaDescription: string;
  mainButton: React.ReactNode;
  benefitsGroups: Benefits[];
  addons?: AddonsGroupProps | null;
  priceDescription?: string;
}

export interface MonthlyProps extends PricePlanBaseProps {
  recurrence: 'monthly';
  descriptionCta: React.ReactNode;
}

export interface AnnualProps extends PricePlanBaseProps {
  recurrence: 'annual';
  monthlyPrice?: string;
}

export type Props = MonthlyProps | AnnualProps;

export function PricePlan({
  title,
  traits,
  price,
  pricePeriod,
  description,
  ctaDescription,
  mainButton,
  benefitsGroups,
  addons,
  ...props
}: Props): React.ReactNode {
  return (
    <div className={styles.container}>
      <div className={clsx(styles.titleContainer, 'mb-24')}>
        <h2 className={clsx(styles.title, 'title-2')} data-test-card-title>
          {title}
        </h2>
        <ProductBadge traits={traits} />
      </div>
      <div data-test-card-price>
        {props.recurrence === 'annual' && Boolean(props.monthlyPrice) && (
          <span className={clsx(styles.strikethroughtPrice, 'title-2')}>{props.monthlyPrice}</span>
        )}
        <span className="title-1">{price}</span>
        <span className="body-1">{pricePeriod}</span>
      </div>
      <div className="mb-24" data-test-card-price-description-text>
        {Boolean(props.priceDescription) && (
          <div className={clsx(styles.priceDescription, 'body-1')} data-test-price-description>
            {props.priceDescription}
          </div>
        )}
        {props.recurrence === 'monthly' && props.descriptionCta}
      </div>
      <p className={clsx(styles.description, 'body-1 mb-24')} data-test-card-description>
        <TextWithHighlights>{description}</TextWithHighlights>
      </p>
      <div>{mainButton}</div>
      <p className={clsx('body-1', styles.ctaDescription)} data-test-card-billing-recurrence>
        {ctaDescription}
      </p>
      <div className={styles.benefitsGroups}>
        {benefitsGroups.map(({ title: groupTitle, benefits }) => (
          <BenefitsGroup benefits={benefits} key={groupTitle} title={groupTitle} />
        ))}
      </div>
      {addons ? (
        <div className={styles.bottomSection}>
          <Divider />
          <AddonsGroup addons={addons.addons} title={addons.title} />
        </div>
      ) : null}
    </div>
  );
}
