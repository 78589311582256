import * as React from 'react';
import { clsx } from 'clsx';
import { ProductBadge } from '../product-badge';
import styles from './add-on-detail.strict-module.css';

interface BaseProps {
  name: string;
  subtitle?: string;
  traits: { name: 'active' | 'popular' | 'free_trial' | 'recommended'; title: string }[];
  price: string;
  pricePeriod: string;
  featuresTitle: string;
  features: string[];
  mainButton?: React.ReactNode;
}

export interface MonthlyProps extends BaseProps {
  recurrence: 'monthly';
  annualSwitch: React.ReactNode;
  pricePrefix?: string;
}

export interface AnnualProps extends BaseProps {
  recurrence: 'annual';
  priceMonthly: string;
  billingRecurrence: string;
  pricePrefix?: string;
}

export type AddOnCardProps = MonthlyProps | AnnualProps;

export function AddOnDetail({
  name,
  subtitle,
  traits,
  price,
  pricePeriod,
  mainButton,
  featuresTitle,
  features,
  ...props
}: AddOnCardProps): React.JSX.Element {
  return (
    <div className={styles.container} data-test-addon-detail-card={name}>
      <div className={styles.top}>
        <div className={clsx(styles.badge, 'mb-8')}>
          <ProductBadge traits={traits} />
        </div>

        <h2 className={clsx(styles.heading, 'title-2')} data-test-addon-detail-card-title>
          {name}
        </h2>

        {subtitle !== undefined && (
          <span className="caption" data-test-addon-detail-card-subtitle>
            {subtitle}
          </span>
        )}
      </div>

      <div>
        <div className="mb-4" data-test-addon-detail-card-priceinfo>
          {props.pricePrefix ? (
            <>
              <span className="title-4">{props.pricePrefix}</span>{' '}
            </>
          ) : null}
          {props.recurrence === 'annual' && (
            <>
              <span className={clsx(styles['price-monthly'], 'title-4')}>{props.priceMonthly}</span>{' '}
            </>
          )}
          <span className="title-4">{price}</span>
          <span className="caption">{pricePeriod}</span>
        </div>

        <div className={styles['price-below']}>
          {props.recurrence === 'monthly' && props.annualSwitch}
          {props.recurrence === 'annual' && (
            <span
              className={clsx(styles.billing, 'caption')}
              data-test-addon-detail-card-billing-recurrence
            >
              {props.billingRecurrence}
            </span>
          )}
        </div>
      </div>

      {mainButton}

      <h3 className={clsx(styles.heading, 'title-4')} data-test-addon-detail-card-feature-title>
        {featuresTitle}
      </h3>

      <ul className={styles.list}>
        {features.map(feature => (
          <li
            className="mb-8 body-2"
            data-test-addon-detail-card-feature-item={feature}
            key={feature}
          >
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
}
